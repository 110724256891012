import React from "react"
import Layout from "../components/layout"
import Hero from "../components/hero"
import Bio from "../components/about/bio"
import ServicesFull from "../components/services/servicesFull"
import FeaturedTestimonial from "../components/testimonials/featuredTestimonial"
import { Section } from "../components/layoutComponents"
import CallToAction from "../components/banners/callToAction"

import ActionBanner from "../images/action-banner.png"

export default function Home() {
  return (
    <>
      <Layout>
        <Hero />
        <Bio />
        <CallToAction
          img={ActionBanner}
          textPrimary="call royco electric today"
        />
        <ServicesFull />
        <div className="section-splitter-bottom"></div>
        <FeaturedTestimonial
          testimonial="The team at Royco Electric always deliver exemplary service. Prompt and professional, they have extensive knowledge in all aspects of their trade that spans many properties types – commercial, residential, mixed-use, and more. Their quality of work is of the highest standard and they’re willing to be flexible to fit your needs. 100% would recommend them to anyone looking for a reliable electrician."
          company="BJL Properties Inc."
          name="krystal bedard"
        />
      </Layout>
    </>
  )
}
